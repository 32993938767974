export default (state = '', action) => {
    switch (action.type) {
        case 'LOGIN':
            return action.access_token;
        
        case 'LOGOUT':
            return '';  
            
        default:
            return state;
    }
}